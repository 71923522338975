import * as React from "react"
import Layout from "../components/layout"
import "../styles/style.css"
import img from "../images/img1.png"
import Amplify from "aws-amplify"
import awsConfig from "../util/scoop-Setting"
import isLocalhost from "../util/localHost"
import storeImg from "../images/StoreImage.jpg"
import iceCream from "../images/iceCream.jpg"
import coffee from "../images/IMG_1787.jpg"
import cake from "../images/cake.jpg"
import Slideshow from "../components/slideShow"
import banner1 from "../images/1.jpg"
// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectURI,
  productionRedirectURI,
] = awsConfig.oauth.redirectURI.split(",");


const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
	redirectURI: isLocalhost ? localRedirectURI : productionRedirectURI,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);

console.log("setting " + JSON.stringify(updatedAwsConfig));


// markup
class IndexPage extends React.Component{
  
  render(){
    

    return (

      <Layout>
          <div className ="container">
            <Slideshow/>
              {/*<div>
                <img style={{margin:'0'}} className="banner" src={ banner1 }/>
              </div>*/}
              <div className = "contents">
              
                  <div className={'grid-container'}>
                    <div className={'img-description'}>
                      <img style={{margin:'0'}} src={cake}></img>
                      
                    </div>
                    <div className={'text-description'}>
                      <h3 style={{fontFamily:'questrial,sans-serif',textTransform:'uppercase'}}>Scoop and Smile</h3>

                      <hr style={{maxWidth:'350px'}}/>
                      <p>Scoop and Smile is home to delicious freshly made Gelatos and crispy homemade waffle cones. We have tasty waffles and refreshing Gelato shakes as well. A range of desserts is coming soon</p>
                      <p>We also have a range of sorbet’s that are dairy free and gluten free and you can get our Gelato in a cup as well.</p>
                      <p>The reasons why launched "Scoop and Smile" in Ellerslie? We are locals who are Desserts and Gelato lovers and we wanted to make our local community smile.</p>
                      <p>Located right in the heart of Ellerslie, next to the fountain, whether you need a little snack come see us!</p>
                    </div>
                  </div>
                  <div className={'banner1'}>
                    <img style={{margin:0,maxHeight:'900px',width:'100%'}} alt="Ice Cream" src={banner1}/>
                  </div>
                  <div className={'grid-container'}>
                  <div className={'text-description'}>
                      <h3 style={{fontFamily:'questrial,sans-serif',textTransform:'uppercase'}}>Our Drinks</h3>

                      <hr style={{maxWidth:'350px'}}/>
                      <p>We have a selection of lots of different types of drinks, iced, hot, smoothies, you name it - we have it! Ask our friendly staff for a recommendation or look at our full display of the beautiful unique iced drinks!</p>

                    </div>
                    <div className={'img-description'}>
                      <img src={coffee}></img>
                    </div>
                  </div>
              </div>
          </div>
          
      </Layout>
        

    )
  }
}

export default IndexPage
