import React from "react";

import  img2 from "../images/bannerImg/2.jpg"
import  img3 from "../images/bannerImg/3.jpg"
import  img4 from "../images/bannerImg/4.jpg"
import  img5 from "../images/bannerImg/5.jpg"
import  img6 from "../images/bannerImg/6.jpg"
import  img7 from "../images/bannerImg/7.jpg"
import  "../styles/slideShow.scss"
const images = [img2,img3,img4,img5,img6,img7]
var slideIndex = 1;
function plusSlides(n) {

    showSlides(slideIndex += n);
    
    }

function currentSlide(n) {
    showSlides(slideIndex = n);
}

function showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    console.log(slides)
    var dots = document.getElementsByClassName("dot");
    if (n > slides.length) {slideIndex = 1}    
    if (n < 1) {slideIndex = slides.length}
    for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";  
    }
    for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[slideIndex-1].style.display = "block";  

}
class Slideshow extends React.Component{
    componentDidMount(){
        
        showSlides(slideIndex);
    }
    
    render(){

    
    

    

    return(

        <div className={"slideshowContainer"}>
            {images.map((name, index) => (
            <div className={"mySlides fade-in-image"} key={index}>

                <img src={name} style={{width:'100%',margin:0}}/>
            </div>
            ))}
            
            <a className="prev" onClick={()=>plusSlides(-1)}>&#10094;</a>
            <a className="next" onClick={()=>plusSlides(1)}>&#10095;</a>
        </div>

    );
}}
export default Slideshow